


























































import { computed, defineComponent } from '~/utils/nuxt3-migration'
import CImg from '~/components/shared/configurable/image/CImg.vue'
import { defineComponentTranslations } from '~/utils/i18n'
import { useI18n } from '~/compositions/i18n'

export default defineComponent({
  components: { CImg },
  setup() {
    const { locale } = useI18n()

    const langToUse = computed(() => (locale === 'el' ? 'el' : 'en'))
    return { langToUse }
  },
  i18n: defineComponentTranslations({
    spare_parts: {
      en: 'Spare parts',
      el: 'Ανταλλακτικά'
    },
    between_professionals: {
      en: 'Only between Professionals',
      el: 'Μόνο μεταξύ Επαγγελματιών'
    },
    banner_subtitle_1: {
      en: 'Spare parts request',
      el: 'Υπηρεσία ζήτησης'
    },
    banner_subtitle_2: {
      en: 'service from Car.gr',
      el: 'ανταλλακτικών από το Car.gr'
    }
  })
})
