










import { computed, defineComponent, toRefs } from '~/utils/nuxt3-migration'
import { facetProps } from '~/constants/quick-search'
import ClassifiedQuickSearchLegacyPickupLocationHandler from '~/components/car/classifieds/quick-search/legacy-facets/handlers/ClassifiedQuickSearchLegacyPickupLocationHandler.vue'
import ClassifiedQuickSearchLegacyCategoryHandler from '~/components/car/classifieds/quick-search/legacy-facets/handlers/ClassifiedQuickSearchLegacyCategoryHandler.vue'
import ClassifiedQuickSearchLegacyPeriodHandler from '~/components/car/classifieds/quick-search/legacy-facets/handlers/ClassifiedQuickSearchLegacyPeriodHandler.vue'
import ClassifiedQuickSearchLegacyOfferTypeHandler from '~/components/car/classifieds/quick-search/legacy-facets/handlers/ClassifiedQuickSearchLegacyOfferTypeHandler.vue'
import ClassifiedQuickSearchLegacyLocationHandler from '~/components/car/classifieds/quick-search/legacy-facets/handlers/ClassifiedQuickSearchLegacyLocationHandler.vue'

export default defineComponent({
  props: { ...facetProps },
  setup(props) {
    const { name } = toRefs(props)

    const handlerComponent = computed(() => {
      switch (name.value) {
        case 'pickup_location': {
          return ClassifiedQuickSearchLegacyPickupLocationHandler
        }
        case 'category': {
          return ClassifiedQuickSearchLegacyCategoryHandler
        }
        case 'rental_period': {
          return ClassifiedQuickSearchLegacyPeriodHandler
        }
        case 'offertype': {
          return ClassifiedQuickSearchLegacyOfferTypeHandler
        }
        case 'location': {
          return ClassifiedQuickSearchLegacyLocationHandler
        }
        default: {
          return null
        }
      }
    })

    return { handlerComponent }
  }
})
