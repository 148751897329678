











































































































































































































































































































import { Classified } from '~/models/classified/types'
import {
  Feed,
  FeedCategory,
  FeedModel,
  FeedTypes,
  FeedUser,
  FeedVideo,
  CustomLinkEntry
} from '~/models/user/types'
import {
  formatAgentsFeed,
  formatCategoryFeed,
  formatFeed,
  formatModelsFeed,
  formatYoutubeFeed,
  formatCustomLinkFeed
} from '~/services/user/formatters'
import { invalidBodyError } from '~/services/errors'
import FeedClassifiedEntry from './feed-entries/FeedClassifiedEntry.vue'
import CShimmer from '~/components/shared/configurable/shimmer/CShimmer.vue'
import { formatNumber } from '~/utils/number'
import LiveFeedControl from './LiveFeedControl.vue'
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart'
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock'
import CDragScroll from '~/components/shared/configurable/CDragScroll.vue'
import { faBars, faSearch } from '@fortawesome/free-solid-svg-icons'
import FeedLinkEntry from './FeedLinkEntry.vue'
import { mapGetters } from 'vuex'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import FeedCategoryEntry from '~/components/car/classifieds/index/feeds/feed-entries/FeedCategoryEntry.vue'
import FeedModelEntry from '~/components/car/classifieds/index/feeds/feed-entries/FeedModelEntry.vue'
import { resolveCategory } from '~/utils/category'
import FeedVideoEntry from '~/components/car/classifieds/index/feeds/feed-entries/FeedVideoEntry.vue'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { CategoryId } from '~/models/category/types'
import { ciDeals } from '~/icons/source/brand/deals'
import { ciPlot } from '~/icons/source/brand/plot'
import { categoriesSolidIconsMappings } from '~/constants/category/icon'
import { ciElectricLeaf } from '~/icons/source/solid/electric-leaf'
import CImg from '~/components/shared/configurable/image/CImg.vue'
import { ciAngleRightLight } from '~/icons/source/solid/angle-right-light'
import { ciBullseyeArrow } from '~/icons/source/solid/bullseye-arrow'
import FeedUserProfileEntry from '~/components/car/classifieds/index/feeds/feed-entries/FeedUserProfileEntry.vue'
import { ciFolderOpen } from '~/icons/source/regular/folder-open'
import { ciCarBlog } from '~/icons/source/solid/car-blog'
import FeedCustomLinkEntry from '~/components/car/classifieds/index/feeds/feed-entries/FeedCustomLinkEntry.vue'
import { ciParkingP } from '~/icons/source/solid/parking-p'
import { defineComponent, PropType } from '~/utils/nuxt3-migration'

interface Data {
  classifieds: Classified[]
  label: object
  seoUrl: string
  count: number
  type: FeedTypes | null
  showLink: boolean
  enableTransitions: boolean
  testCounter: number
  transitionName: string
  queue: object[]
  queueInterval: object
  fetchInterval: object
  firstFetchTry: boolean
  categoryIds: number[]
  agents: FeedUser[]
  hiddenClassifieds: Set<string>
  forcePause: boolean
  categories: FeedCategory[]
  icon: string
  models: FeedModel[]
  videos: FeedVideo[]
  linksData: CustomLinkEntry[]
}

export default defineComponent({
  components: {
    FeedUserProfileEntry,
    FeedModelEntry,
    FeedVideoEntry,
    FeedCategoryEntry,
    FeedClassifiedEntry,
    CShimmer,
    LiveFeedControl,
    CDragScroll,
    FeedLinkEntry,
    CImg,
    FeedCustomLinkEntry
  },
  props: {
    fetchUrl: {
      type: String,
      required: false,
      default: null
    },
    fetchableType: {
      type: String as PropType<FeedTypes>,
      required: true
    },
    refreshInterval: {
      type: Number || null,
      required: false,
      default: 10 // in seconds
    },
    lazyLoad: {
      type: Boolean,
      required: false,
      default: false
    },
    maxClassifieds: {
      type: Number,
      required: false,
      default: 50
    },
    loadedFeed: {
      type: Object as PropType<Feed>,
      required: false,
      default: undefined
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    inheritBodyBg: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data(): Data {
    return {
      classifieds: [],
      label: null,
      seoUrl: null,
      type: null,
      count: null,
      showLink: true,
      enableTransitions: false,
      testCounter: 0,
      transitionName: 'entry',
      queue: [],
      queueInterval: null,
      fetchInterval: null,
      firstFetchTry: false,
      categoryIds: [],
      hiddenClassifieds: new Set(),
      forcePause: true,
      categories: [],
      agents: [],
      icon: '',
      models: [],
      videos: [],
      linksData: []
    }
  },
  computed: {
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc'
    }),
    icons() {
      return {
        chevronRight: ciAngleRightLight,
        heart: faHeart,
        clock: faClock,
        folder: ciFolderOpen,
        parking: ciParkingP,
        list: faBars,
        youtube: faYoutube,
        search: faSearch,
        electric: ciElectricLeaf,
        target: ciBullseyeArrow,
        plot: ciPlot,
        blog: ciCarBlog
      }
    },
    useRouter() {
      return !this.isModelsFeed
    },
    liveFeedActive() {
      if (this.fetchInterval) {
        return true
      }
      return false
    },
    randomShimmerWidth() {
      return Math.floor(Math.random() * 40) + 40 + '%'
    },
    isCategoryFeed() {
      return (
        this.fetchableType === FeedTypes.CATEGORIES ||
        this.isElectricVehiclesFeed
      )
    },
    isModelsFeed() {
      return this.fetchableType === FeedTypes.MODELS
    },
    isYoutubeFeed() {
      return this.fetchableType === FeedTypes.YOUTUBE
    },
    isCustomLinkFeed() {
      return this.fetchableType === FeedTypes.CUSTOM_LINK
    },
    isElectricVehiclesFeed() {
      return this.fetchableType === FeedTypes.ELECTRIC_VEHICLES
    },
    isAgentsFeed() {
      return this.fetchableType === FeedTypes.AGENTS_FEED
    },
    isSupportedFeed() {
      return Object.values(FeedTypes).includes(this.fetchableType)
    },
    isUserClassifieds() {
      return this.type === FeedTypes.USER_CLASSIFIEDS
    },
    isParking() {
      return this.type === FeedTypes.FAVORITE_CLASSIFIEDS
    },
    isFavoriteSearch() {
      return (
        this.type === FeedTypes.FAVORITE_USER_SEARCH ||
        this.type === FeedTypes.FAVORITE_CLASSIFIEDS
      )
    },
    isRecentSearch() {
      return (
        this.type === FeedTypes.RECENT_USER_SEARCH ||
        this.type === FeedTypes.RECENT_VIEWED_CLASSIFIEDS
      )
    },
    isTopPicksFeed() {
      return this.type === FeedTypes.TOP_PICKS
    },
    isListSearch() {
      return this.type === FeedTypes.LIST
    },
    isDealsSearch() {
      return this.type === FeedTypes.DEALS
    },
    isGenericSearch() {
      return (
        this.type === null &&
        this.categoryIds.length === 1 &&
        this.categoryIds[0] === CategoryId.CLASSIFIEDS
      )
    },
    showMoreShimmer() {
      return !this.isTopPicksFeed && this.label === null
    },
    isNormalSearch() {
      return Boolean(
        !(
          this.isFavoriteSearch ||
          this.isRecentSearch ||
          this.isUserClassifieds ||
          this.isListSearch ||
          this.isCategoryFeed
        ) && this.categoryIds.length
      )
    },
    hasExternalLinks() {
      return this.isYoutubeFeed || this.isCustomLinkFeed
    },
    feedEntries() {
      if (this.isCategoryFeed) {
        return this.categories
      }
      if (this.isAgentsFeed) {
        return this.agents
      }
      if (this.isModelsFeed) {
        return this.models
      }

      if (this.isYoutubeFeed) {
        return this.videos
      }
      if (this.isCustomLinkFeed) {
        return this.linksData
      }
      return this.classifieds
    },
    mainShimmerHeight() {
      if (this.isPc) {
        if (this.isYoutubeFeed) {
          return '228px'
        }
        if (this.isCategoryFeed) {
          return '230px'
        }
        return '277px'
      }

      if (this.isYoutubeFeed) {
        return '180px'
      }
      if (this.isCategoryFeed) {
        return '229px'
      }
      return '226px'
    },
    feedSubtitleCountPrefix() {
      return this.isCategoryFeed && !this.isElectricVehiclesFeed
        ? this.$t('categories').toLowerCase()
        : this.$t('classifieds').toLowerCase()
    },
    feedSubtitleString() {
      const countText = this.count
        ? `${this.formatNumber(this.count)} ${this.feedSubtitleCountPrefix}`
        : ''
      if (!this.label?.long_title) {
        return countText
      }

      if (this.count) {
        return `${this.label.long_title} - ${countText}`
      }
      return this.label.long_title
    },
    isRental() {
      return this.categoryIds.includes(CategoryId.RENTALS)
    }
  },
  beforeDestroy(): void {
    clearInterval(this.fetchInterval)
    clearInterval(this.queueInterval)
  },
  mounted() {
    if (!this.isSupportedFeed) {
      this.$emit('remove-feed')
    }
    if (!this.lazyLoad) {
      this.fetchFeed()
      // else it will be controlled by parent element's scroll
    }
  },
  methods: {
    getIcon(categoryIds) {
      if (this.type === FeedTypes.DEALS) {
        return ciDeals
      } else if (this.type === FeedTypes.PLOT) {
        return ciPlot
      }
      return resolveCategory(categoriesSolidIconsMappings, categoryIds)
    },
    feedClicked(e, ns = 'n_index_feed_click') {
      if (this.seoUrl) {
        e.preventDefault()
        this.$analytics.recordEvent({
          namespace: ns,
          action: 'click',
          label:
            this.label && this.label.short_title
              ? this.label.short_title
              : 'nolabel'
        })
        if (this.hasExternalLinks) {
          window.open(this.seoUrl)
        } else if (this.useRouter) {
          return this.$router.push({
            path: this.seoUrl
          })
        } else {
          window.location.href = this.seoUrl
        }
      }
    },
    async startFetching() {
      await this.fetchFeed()
      this.setFetchInterval()
    },
    setFetchInterval() {
      if (process.client && this.fetchInterval === null) {
        // only set interval on client (else we got weird server intervals)
        this.fetchInterval = setInterval(
          this.fetchIfVisible,
          this.refreshInterval * 1000
        )
      }
    },
    fetchIfVisible() {
      if (this.isVisible()) {
        this.fetchFeed()
      }
    },
    feedControlClick() {
      if (this.fetchInterval) {
        // pause clicked
        this.$analytics.recordEvent({
          namespace: 'n_index_feed_control_clicked',
          action: 'click',
          label: 'pause clicked'
        })

        this.clearFetchInterval()
        if (this.$refs.scroller && this.$refs.scroller.scrollX >= 0) {
          this.forcePause = true
        }
      } else {
        // play clicked
        this.$analytics.recordEvent({
          namespace: 'n_index_feed_control_clicked',
          action: 'click',
          label: 'play clicked'
        })

        if (this.$refs.scroller && this.$refs.scroller.scrollX < 0) {
          this.$refs.scroller.moveToStart()
        }
        this.forcePause = false

        if (this.fetchInterval === null) {
          this.startFetching()
        }

        if (this.queueInterval === null) {
          this.queueInterval = setInterval(this.checkQueue, 2000)
        }
      }
    },
    clearFetchInterval() {
      clearInterval(this.fetchInterval)
      this.fetchInterval = null
    },
    isVisible() {
      if (document) {
        // hide if window/tab is not visible
        if (typeof document.hidden !== 'undefined') {
          if (document.hidden) {
            return false
          }
        }

        // if (typeof document.hasFocus() !== 'undefined') {
        //   if (!document.hasFocus()) {
        //     return false
        //   }
        // }
        const elem = this.$refs.feed
        const offset = 200
        let headerHeight = 0

        const desktopHeader = document.getElementById('header-section')
        if (desktopHeader) {
          headerHeight = desktopHeader.offsetHeight
        }

        const docViewTop = document.documentElement.scrollTop
        const docViewBottom = docViewTop + window.innerHeight

        let elemTop = headerHeight
        let offsetElem = elem
        if (offsetElem.offsetParent) {
          do {
            elemTop += offsetElem.offsetTop
            offsetElem = offsetElem.offsetParent
          } while (offsetElem)
        }

        if (elemTop < 0) {
          elemTop = headerHeight
        }

        const elemBottom = elemTop + elem.offsetHeight

        return (
          elemTop - offset <= docViewBottom &&
          elemBottom - headerHeight + offset >= docViewTop + headerHeight
        )
      }
      return false
    },
    showLinkStatus(state) {
      this.showLink = state
    },
    checkQueue() {
      // only add stuff if we are scrolled at the beginning
      let startCheck = false
      if (
        this.$refs.scroller &&
        this.$refs.scroller.scrollX >= 0 &&
        !this.forcePause
      ) {
        startCheck = true
        if (this.fetchInterval === null) {
          this.startFetching()
        }
      } else {
        this.clearFetchInterval()
        return
      }
      if (this.queue.length && startCheck) {
        this.transitionName = 'entry'
        // this.classifieds.unshift(...testClassifieds)
        this.classifieds.unshift(this.queue[0])
        this.$nextTick(() => {
          if (this.classifieds.length > this.maxClassifieds) {
            // remove the last one and add it to hidden
            const deletedClsfd = this.classifieds.pop()
            this.hiddenClassifieds.add(deletedClsfd.id)
          }
        })

        // remove first entry
        this.queue.shift()
      }
    },
    addTestEntries() {
      const testClassifieds = [
        {
          id: this.testCounter + 1,
          category_ids: [20001, 20002, 20003, 20006, 20016],
          title: 'TEST' + (this.testCounter + 1),
          price: '45.222 €',
          thumb_pattern: 'https://static.plot.gr/38123271_0_{size}.jpg',
          seo_url: '/plot/view/38123271-mezoneta-3235-tm-pros-enoikiasi',
          has_photos: true,
          modified: new Date().getTime()
        },
        {
          id: this.testCounter + 2,
          category_ids: [20001, 15000, 15001, 15218],
          title: 'TEST' + (this.testCounter + 2),
          price: '3.445 €',
          thumb_pattern: 'https://static-cz.car.gr/40022717_1_{size}.jpeg',
          seo_url: '/xyma/view/12073974-the-best-laptop-in-the-universe',
          has_photos: true,
          modified: new Date().getTime()
        },
        {
          id: this.testCounter + 3,
          category_ids: [20001, 20002, 20003, 20006, 20015],
          title: 'TEST' + (this.testCounter + 3),
          price: '3.445 €',
          thumb_pattern: 'https://static-cz.car.gr/40022718_2h_{size}.jpeg',
          seo_url: '/xyma/view/12073974-the-best-laptop-in-the-universe',
          has_photos: true,
          modified: new Date().getTime()
        },
        {
          id: this.testCounter + 4,
          category_ids: [20001, 50, 4112, 4640, 5381],
          title: 'TEST' + (this.testCounter + 4),
          price: '45.222 €',
          thumb_pattern: 'https://static.plot.gr/38123271_0_{size}.jpg',
          seo_url: '/plot/view/38123271-mezoneta-3235-tm-pros-enoikiasi',
          has_photos: true,
          modified: new Date().getTime()
        },
        {
          modified: new Date().getTime(),
          id: this.testCounter + 5,
          category_ids: [20001, 50, 2915, 5905, 3931, 7888],
          title: 'TEST' + (this.testCounter + 5),
          price: '2.596 €',
          seo_url: '/classifieds/cars/view/1644603-nissan-x-trail',
          thumb_pattern: 'https://static.car.gr/1644603_0_{size}.jpg',
          has_photos: true
        }
      ]

      this.testCounter += testClassifieds.length

      this.queue.push(...testClassifieds)
    },
    afterTransitionEnd() {
      this.$nextTick(() => {
        if (this.$refs.scroller) {
          if (this.transitionName === 'empty') {
            this.$refs.scroller.scrollX = 0
          }
          this.$refs.scroller.calcDsWidth()
        }
      })
    },
    formatNumber(num: number) {
      return formatNumber(num)
    },
    refetch() {
      this.classifieds = []
      this.fetchFeed()
    },
    async fetchFeed() {
      try {
        if (!this.firstFetchTry && this.loadedFeed) {
          this.firstFetchTry = true
          this.setFeedData(this.loadedFeed, false)
          return
        }

        this.firstFetchTry = true
        const response = await this.$axios.get(this.fetchUrl)

        const body = response.data
        if (!(body && body.data && body.data.feed)) {
          throw invalidBodyError(body)
        }

        this.setFeedData(body.data.feed, true)
      } catch (error) {
        if (error.response && error.response.data.status === 476) {
          // retry after 7sec
          setTimeout(() => {
            this.fetchFeed()
          }, 7000)
        } else {
          // some generic kind of an error, so hide the feed (remove this for debugging)
          this.$emit('remove-feed')
        }
        error.message = `Failed to fetch feed with url ${this.fetchUrl} with error: ${error}`
        this.$logger.captureError(error)
      }
    },
    setFeedData(feed: any, format = false) {
      if (this.isCategoryFeed) {
        if (format) {
          feed = formatCategoryFeed(feed)
        }
        const { categories, label, type, seoUrl, count, icon } = feed

        this.categories = categories
        this.label = label
        this.type = type
        this.seoUrl = seoUrl
        this.count = count
        this.icon = icon
      } else if (this.isAgentsFeed) {
        if (format) {
          feed = formatAgentsFeed(feed)
        }
        const { agents, label, type, seoUrl } = feed

        this.agents = agents
        this.label = label
        this.type = type
        this.seoUrl = seoUrl

        if (this.agents.length === 0) {
          this.$emit('remove-feed')
        }
      } else if (this.isModelsFeed) {
        if (format) {
          feed = formatModelsFeed(feed)
        }
        const { models, label, type, seoUrl, categoryIds } = feed

        this.models = models
        this.label = label
        this.type = type
        this.seoUrl = seoUrl
        this.categoryIds = categoryIds

        if (this.models.length === 0) {
          this.$emit('remove-feed')
        }
      } else if (this.isYoutubeFeed) {
        if (format) {
          feed = formatYoutubeFeed(feed)
        }
        const { videos, label, type, seoUrl } = feed

        this.videos = videos
        this.label = label
        this.type = type
        this.seoUrl = seoUrl

        if (this.videos.length === 0) {
          this.$emit('remove-feed')
        }
      } else if (this.isCustomLinkFeed) {
        if (format) {
          feed = formatCustomLinkFeed(feed)
        }
        const { linkData, label, type, seoUrl, icon } = feed
        this.label = label
        this.type = type
        this.seoUrl = seoUrl
        this.linksData = linkData
        this.icon = icon
      } else {
        if (format) {
          feed = formatFeed(feed)
        }
        const { classifieds, label, type, seoUrl, count, categoryIds } = feed

        this.label = label
        this.type = type
        this.categoryIds = categoryIds
        this.seoUrl = seoUrl
        this.count = count

        if (
          !classifieds ||
          classifieds.length === 0 ||
          (type === FeedTypes.FAVORITE_CLASSIFIEDS && classifieds.length < 5)
        ) {
          this.$emit('remove-feed')
          return
        }

        if (!this.seoUrl) {
          // possibly a parking feed
          switch (this.type) {
            case FeedTypes.FAVORITE_USER_SEARCH:
              this.seoUrl = '/parking/searches/'
              break
            case FeedTypes.FAVORITE_CLASSIFIEDS:
              this.seoUrl = '/parking/'
              break
            case FeedTypes.RECENT_USER_SEARCH:
              this.seoUrl = '/parking/recent/searches/'
              break
            case FeedTypes.RECENT_VIEWED_CLASSIFIEDS:
              this.seoUrl = '/parking/recent/classifieds/'
              break
            case FeedTypes.LIST:
              this.seoUrl = '/parking/lists/'
          }
        }

        if (this.classifieds.length) {
          // we already have some classifieds so queue the new ones

          const newClassifieds = classifieds.filter(clsfd => {
            return !(
              this.classifieds.some(c => c.id === clsfd.id) ||
              this.hiddenClassifieds.has(clsfd.id) ||
              this.queue.some(c => c.id === clsfd.id)
            )
          })

          if (newClassifieds.length) {
            this.queue.push(...newClassifieds)
          }
        } else {
          this.enableTransitions = true
          this.transitionName = 'empty'

          this.$nextTick(() => {
            this.classifieds = classifieds
          })
        }
      }
    }
  }
})
